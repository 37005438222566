.members-list-container {
    background-color: var(--sitePalette2);
    border-radius: 0.8rem;
    color: var(--sitePalette4);
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-top: 1rem;
    max-width: 20rem;
    padding: 1rem 1.25rem 0.25rem 0;
}

.members-list {
    list-style-type: "- ";
}
.home-paragraph {
    background-color: var(--sitePalette2);
    border-radius: 0.8rem;
    color: var(--sitePalette4);
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-top: 1rem;
    max-width: 50rem;
    padding: 0.5rem 1.25rem 0.75rem 1.25rem;
}

.home-schedule-list {
    margin-top: 1rem;
}
.home-schedule-list-item {
    list-style-type: none;
}
.home-schedule-list-item-current {
    list-style-type: "🍺 ";
}
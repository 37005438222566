.highlight {
    background-color: yellow;
}

.rating-container-divider {
    background: var(--sitePalette3);
    background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 250, from(var(--iconSecondary)), to(var(--sitePalette1)));
    border: none;
    color: white;
    display: block;
    height: 1px;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.ratings-paragraph {
    background-color: var(--sitePalette2);
    border-radius: 0.8rem;
    color: var(--sitePalette4);
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-top: 1rem;
    max-width: 50rem;
    padding: 0.75rem 1.25rem 0.75rem 1.25rem;
}

.ratings-search {
    margin: 0 0 1.25rem 2rem;
} .ratings-search label {
    color: var(--iconPrimary);
    font-size: 1.2rem;
    padding: 0.75rem;
}

.rating-media-container {
    /*border-bottom: 0.1rem solid var(--sitePalette3);*/
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    margin-left: 1rem;
    /*padding-bottom: 1rem;*/
}

.rating-media-left {
    margin-bottom: 2rem;
    /* max-width: 8rem; */
    /* min-width: 14rem; */
    text-align: center;
}

.rating-media-image {
    border: 0.1rem solid rgba(195, 195, 195, 0.75);
    border-radius: 0.5rem;
    margin: 0.5rem;
    max-height: 128px;
    min-height: 128px;
    max-width: 128px;
    min-width: 128px;
    padding: 0.2rem;
}

.rating-media-body {
    /*border-left: 0.15rem solid var(--sitePalette3);*/
    max-width: 50vw;
    /* min-width: 30rem; */
}

.rating-media-header {
    display: block;
    font-size: 1.3rem;
    font-weight: bold;
}

.rating-media-subheader {
    color: var(--sitePalette4);
    display: block;
    font-size: 1rem;
    margin-bottom: 0.6rem;
    /*margin-left: 0.4rem;*/
}

.review-media-list {
    color: var(--sitePalette4);
    font-size: 0.85rem;
    margin: 0;
    padding-left: 1.5rem;
}

.review-media-score {
    display: block;
    margin-left: 0.75rem;
}

.review-media-header {
    color: var(--sitePalette5);
    font-size: .95rem;
    font-weight: bold;
}

.review-media-content {
    padding-left: 1rem;
}

@media(max-width: 600px) {
    .ratings-search {
        width: 50vw;
        text-align: center;
    } .ratings-search label {
        padding: 0 0.75rem 0 0;
    }

    .rating-media-container {
        display: grid;
        grid-template-columns: 1fr;
        margin: auto;
        /* margin-left: 1rem; */
        /* grid-template-rows: 1fr 2fr; */
        width: 85vw;
    }

    .rating-media-left {
        min-width: 85vw;
        text-align: center;
    }
}
:root {
    /* Brown-ish */
    /*--sitePalette1: #201f1d;
    --sitePalette2: #868685;
    --sitePalette3: #de8d47;
    --sitePalette4: #dcdcdc;
    --sitePalette5: #ffffff;*/
    /* Purple-ish */
    /*--sitePalette1: #222831;
    --sitePalette2: #30475e;
    --sitePalette3: #f2a365;
    --sitePalette4: #ececec;
    --sitePalette5: #ffffff;*/
    /* Shades of blue */
    /*--sitePalette1: #1b262c;
    --sitePalette2: #0f4c75;
    --sitePalette3: #3282b8;
    --sitePalette4: #bbe1fa;
    --sitePalette5: #ffffff;*/
    /* Ash */
    --sitePalette1: #1e2022;
    --sitePalette2: #52616b;
    --sitePalette3: #c9d6df;
    --sitePalette4: #f0f5f9;
    --sitePalette5: #ffffff;
    --transition-speed: 600ms;
}

body {
    background-color: var(--sitePalette1);
    color: var(--sitePalette5);
    transition: var(--transition-speed);
}

h2 {
    color: var(--iconPrimary);
    margin: auto auto 1.75rem 1rem;
}

.list-group {
    color: var(--sitePalette2);
}

.main-content-container {
    margin-left: 5rem;
    padding: 1rem;
}

/* Small screens */
@media only screen and (max-width: 600px) {
    .main-content-container {
        margin: 0;
    }
}